<template>
  <div>
    <LiefengContent>
      <!-- <template v-slot:title>{{ menuCodeObj.menuName||"居务公开" }}</template> -->
      <template v-slot:title>{{"居民移除社区记录"}}</template>
      <template v-slot:toolsbarRight>
        <Form :model="searchData" :label-colon="true" :inline="true" class="search">
          <FormItem label="选择范围" :label-width="75">
              <CascaderCity @changeCasader="changeCasader" :resetNum="resetNum" :orgCode="4401" :width="200"></CascaderCity>
          </FormItem>
          <FormItem :label-width="50" style="margin-bottom: 0">
              <Input v-model.trim="searchData.name" placeholder="按姓名查询" style="width: 160px"></Input>
          </FormItem>
          <FormItem :label-width="60">
              <Input v-model.trim="searchData.phone" placeholder="按手机号查询" style="width: 160px"></Input>
          </FormItem>
          <Button type="primary" icon="ios-search" @click="searchBtn('btn')" style="margin-right: 10px">查询</Button>
          <Button type="success" @click="resetBtn" icon="ios-refresh" style="margin-right: 10px">重置</Button>
        </Form>
      </template>

      <template v-slot:contentArea>
        <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :loading="loading"
        :fixTable="true"
        :curPage="page"
        :total="total"
        :pagesizeOpts="[20, 30, 50, 100]"
        :page-size="page_size"
        @hadlePageSize="hadlePageSize"
        ></LiefengTable>
      </template>
      
    </LiefengContent>
  </div>
</template>

<script>
import CascaderCity from './component/CascaderCity'
import LiefengTable from "@/components/LiefengTable";
import LiefengContent from "@/components/LiefengContent3";

export default {
  components: {
    LiefengContent,
    CascaderCity,
    LiefengTable
  },
  data() {
    return {
      searchData: {
        name: '',
        phone: '',
        orgCode: []
      },
      talbeColumns: [
        {
          title: "区",
          key: "zoneName",
          minWidth: 200,
          align: "center"
        },
        {
          title: "街道",
          key: "streetName",
          minWidth: 200,
          align: "center"
        },
        {
          title: "社区名称",
          key: "communityName",
          minWidth: 200,
          align: "center"
        },
        {
          title: "姓名",
          key: "name",
          minWidth: 200,
          align: "center"
        },
        {
          title: "联系电话",
          key: "phone",
          minWidth: 200,
          align: "center"
        },
        {
          title: "现居地址",
          key: "currentAddr",
          minWidth: 200,
          align: "center"
        },
        {
          title: "移除时间",
          key: "moveTime",
          minWidth: 200,
          align: "center",
          render:(h,params) =>{
            return h ('div',{},params.row.moveTime ? this.$core.formatDate(new Date(params.row.moveTime),"yyyy-MM-dd hh:mm:ss") :'')
          }
        },
      ],
      tableData: [],
      loading: false,
      page: 1,
      page_size: 20,
      resetNum: 0
    }
  },
  methods: {
    changeCasader(val) {
      this.searchData.orgCode = val
    },
    searchBtn(val) {
      if (this.searchData.orgCode.length != 3 || this.searchData.orgCode == []) {
        this.$Message.error({
          content:'请选择发布范围下的社区',
          background:true
        })
        return
      }
      if (val) {
        this.page = 1
      }
      this.loading = true
      let orgCode = this.searchData.orgCode[2]
      this.$get('/syaa/api/syuser/pc/userCommunity/selectUserMoveOrgPage', {
        name: this.searchData.name || "",
        phone: this.searchData.phone || "",
        orgCode: orgCode,
        page: this.page,
        pageSize: this.page_size
      }).then(res => {
        this.loading = false
        this.tableData = res.dataList
        this.total = res.maxCount
      })
    },
    resetBtn() {
      this.searchData = {
        name: '',
        phone: '',
        changeCasader: ''
      }
      this.resetNum = this.resetNum + 1
    },
    hadlePageSize(obj) {
      this.page = obj.page
      this.page_size = obj.pageSize
      this.searchBtn()
    }
  }
}
</script>

<style>

</style>